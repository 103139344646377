// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import { AxiosResponse } from "axios";
import { ExamData, LoginDataDef, RefreshTokenDataDef } from "../../types/type";
import api from "../../utils/api";

export const getExam = (): Promise<AxiosResponse> => {
  return api.get("/exams/1");
};

export const getBanners = (): Promise<AxiosResponse> => {
  return api.get("/banners");
};

export const getTeachers = (): Promise<AxiosResponse> => {
  return api.get("/teachers");
};

export const getStudents = (): Promise<AxiosResponse> => {
  return api.get("/students");
};

export const getPosts = (): Promise<AxiosResponse> => {
  return api.get("/posts");
};

export const postAnswersExam = async (data: ExamData) => {
  try {
    const response = await api.post("/exams/1/exam-results", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const loginApi = (data: LoginDataDef): Promise<AxiosResponse> => {
  return api.post("/login", data);
};

const logoutApi = (): Promise<AxiosResponse> => {
  return api.delete("/logout");
};

const refreshTokenApi = (data: RefreshTokenDataDef): Promise<AxiosResponse> => {
  return api.post("/login", data);
};

export const getListDocumentsApi = (): Promise<AxiosResponse> => {
  return api.get("/documents");
};

export const downloadDocumentApi = (id: string): Promise<AxiosResponse> => {
  return api.get(`/documents/${id}/download`);
};

const webApi = {
  getExam,
  getBanners,
  getTeachers,
  getStudents,
  getPosts,
  postAnswersExam: postAnswersExam,
  loginApi,
  logoutApi,
  refreshTokenApi,
  getListDocumentsApi,
  downloadDocumentApi,
};

export default webApi;
