import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { postRefreshToken } from "../redux/web.slice";
import store from "../redux/store";
import toCamelCase from "./toCamelCase";
import toSnakeCase from "./toSnakeCase";
import { trimValue } from "./trimValue";

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = (request: AxiosRequestConfig) => {
  const requestConfig: AxiosRequestConfig = trimValue(request);
  requestConfig.params = toSnakeCase(requestConfig.params);
  requestConfig.data = toCamelCase(requestConfig.data);
  requestConfig.auth = {
    username: "uni",
    password: "Unienglish@123",
  };

  const { accessToken } = store.getState().web;
  if (accessToken && requestConfig.headers) {
    requestConfig.headers.AuthorizationAPI = `Bearer ${accessToken}`;
  }

  return requestConfig;
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */
const errorInterceptor = (axiosError: AxiosError) => {
  return Promise.reject(axiosError);
};

/**
 * Axios success response interceptor
 * @param {AxiosResponse} response
 */

const responseInterceptor = (response: AxiosResponse) => {
  response.data = toCamelCase(response.data);
  return response;
};

/** Setup an API instance */
const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: false,
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async () => {
  const { refreshToken } = store.getState().web;
  if (refreshToken) {
    await store.dispatch(
      postRefreshToken({
        refreshToken,
        grantType: "refreshToken",
      })
    );
    return Promise.resolve();
  }
  return Promise.reject();
};
createAuthRefreshInterceptor(api, refreshAuthLogic);

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(responseInterceptor, errorInterceptor);

export default api;
