/* eslint-disable @typescript-eslint/no-explicit-any */
import snakeCase from "lodash.snakecase";

const isArray = (d: any) => Array.isArray(d);
const isObject = (d: any) =>
  d === Object(d) && !isArray(d) && typeof d !== "function";

const toSnakeCase = (d: any) => {
  if (isObject(d)) {
    const o: any = {};
    Object.keys(d).forEach((k) => {
      o[snakeCase(k)] = toSnakeCase(d[k]);
    });

    return o;
  }
  if (isArray(d)) {
    return d.map((o: any) => toSnakeCase(o));
  }

  return d;
};

export default toSnakeCase;
