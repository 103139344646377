import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import get from "lodash.get";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import webApi from "../pages/api/webApi";
import {
  LoginDataDef,
  RefreshTokenDataDef,
  DocumentDataDef,
} from "../types/type";

export type WebState = {
  accessToken: string | null;
  refreshToken: string | null;
  listDocuments: DocumentDataDef | null;
};

export const postLogin = createAsyncThunk(
  "web/postLogin",
  async (data: LoginDataDef, { rejectWithValue }) => {
    try {
      const response = await webApi.loginApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      return rejectWithValue(error);
    }
  }
);

export const postLogOut = createAsyncThunk(
  "web/postLogout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await webApi.logoutApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      return rejectWithValue(error);
    }
  }
);

export const postRefreshToken = createAsyncThunk(
  "web/postRefreshToken",
  async (data: RefreshTokenDataDef, { rejectWithValue }) => {
    try {
      const response = await webApi.refreshTokenApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getListDocuments = createAsyncThunk(
  "web/listDocuments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await webApi.getListDocumentsApi();
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      return rejectWithValue(error);
    }
  }
);

const initialState: WebState = {
  accessToken: "",
  refreshToken: "",
  listDocuments: null,
};

const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ================= postLogin
    builder.addCase(postLogin.fulfilled, (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    });
    builder.addCase(postLogin.rejected, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    });
    // ================= postRefreshToken
    builder.addCase(postRefreshToken.fulfilled, (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    });
    builder.addCase(postRefreshToken.rejected, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    });
    // ================= logout
    builder.addCase(postLogOut.fulfilled, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    });
    builder.addCase(postLogOut.rejected, (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    });
    // ================= list document
    builder.addCase(getListDocuments.fulfilled, (state, action) => {
      state.listDocuments = action.payload;
    });
    builder.addCase(getListDocuments.rejected, (state) => {
      state.listDocuments = null;
    });
  },
});

export const { updateAccessToken } = webSlice.actions;

const webConfig = {
  key: "web",
  storage,
  whitelist: ["accessToken", "refreshToken"],
};

export const webReducer = persistReducer<WebState>(webConfig, webSlice.reducer);
