import "../styles/globals.css";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, CssBaseline } from "@mui/material";

import createEmotionCache from "../utility/createEmotionCache";
import lightTheme from "../styles/theme/lightTheme";
import "../styles/globals.css";
import Head from "next/head";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "../redux/store";

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: any) {
  return (
    <CacheProvider value={emotionCache}>
      <Provider store={store} key="provider">
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <Head>
              <title>Uni english center</title>
            </Head>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
}

export default MyApp;
